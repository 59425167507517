//
//
//
//
//
//
//
//
//
//
//
//
//

import config from '/app.config'
  export default {
    name: "TheAuth",
    data: function () {
      return {
        config,
      }
    },
  }
